import { Card, Divider, Fade, Grid, IconButton, Skeleton } from "@mui/material";
import { ReactComponent as GoBack } from "assets/images/GoBackIcon.svg";
import pxToRem from "assets/theme/functions/pxToRem";
import { Alert, Box, Button, Input, Typography } from "components";
import { getIcon } from "data/icons/icon";
import { OTPLogin } from "modules";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { isEmail } from "services/validations/input";
import BasicLayout from "views/auths/components/BasicLayout";

function Login({
  getProvidersState,
  setSelectedAuthProvider,
  requestAuth,
  requestAuthState,
  verifyAuth,
  verifyAuthState,
  openSocialAuthPopup,
  handleClearErrorAndRetry,
}) {
  const { translations } = useSelector((state) => state.generals);
  const [email, setEmail] = useState("");
  const [inputErrorMessage, setInputErrorMessage] = useState("");
  const [isOtpFieldVisible, setIsOtpFieldVisible] = useState(false);
  const handleChange = (value, type) => {
    switch (type) {
      case "email":
        setInputErrorMessage("");
        setEmail(value);
        break;
      default:
        break;
    }
  };
  const sendOtpRequest = async () => {
    // if (!email) {
    if (!email || !isEmail(email)) {
      setInputErrorMessage(translations.INVALID_EMAIL);
      return;
    }
    if (!getProvidersState.isSuccess) return;

    const magicLinkProvider = getProvidersState.data.providers.find(
      (provider) => provider.code === "magic_link"
    );
    const body = {
      provider_id: magicLinkProvider.id,
      email,
    };
    setSelectedAuthProvider(magicLinkProvider);
    const isRequestSuccessful = await requestAuth(body);
    if (isRequestSuccessful) {
      setIsOtpFieldVisible(true);
    }
  };
  const handleOtpSubmission = (otp) => {
    if (!getProvidersState.isSuccess) {
      return;
    }
    const magicLinkProvider = getProvidersState.data.providers.find(
      (provider) => provider.code === "magic_link"
    );

    const body = {
      provider_id: magicLinkProvider.id,
      token: otp,
      email,
    };
    verifyAuth(body);
  };

  const handleEditEmail = () => {
    setIsOtpFieldVisible(false);
  };
  const navMenu = [translations.HOW_THIS_WORKS, translations.PRICING, translations.CONTACT].map(
    (nav) => ({
      name: nav,
      route: nav.split("_").join("-").toLowerCase(),
    })
  );
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      sendOtpRequest();
    }
  };
  return (
    <>
      {requestAuthState.isError && isOtpFieldVisible ? (
        <Alert
          alertType="error"
          toggle={requestAuthState.isError}
          handleCloseError={handleClearErrorAndRetry}
          duration={0}
        >
          {requestAuthState.error?.message}
        </Alert>
      ) : null}
      <BasicLayout navMenu={navMenu}>
        <Card
          className="flex-container-column align-items-start overflow-hidden box-shadow justify-content-between"
          sx={{
            height: isOtpFieldVisible ? pxToRem(290) : pxToRem(410),
            padding: "2rem 2.75rem",
            width: {
              lg: pxToRem(508),
              sm: "unset",
            },
            gap: 2,
          }}
        >
          {isOtpFieldVisible && (
            <Box className="flex-container" onClick={handleEditEmail}>
              <Button variant="text" color="primary">
                <GoBack />
                <Typography variant="d8" color="inherit" sx={{ marginLeft: pxToRem(10) }}>
                  Go back
                </Typography>
              </Button>
            </Box>
          )}
          <Box width="100%" height={pxToRem(28)}>
            <Typography
              variant="h4"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  fontSize: pxToRem(20),
                },
              })}
            >
              {isOtpFieldVisible ? translations.CONFIRM_EMAIL : translations.LOGIN_CARD_HEADER}
            </Typography>
          </Box>
          {(getProvidersState.isError || requestAuthState.isError) && !isOtpFieldVisible ? (
            <Alert
              alertType="error"
              toggle={getProvidersState.isError || requestAuthState.isError}
              handleCloseError={handleClearErrorAndRetry}
              duration={0}
            >
              {getProvidersState.error?.message || requestAuthState.error?.message}
            </Alert>
          ) : null}
          <Box component="form" role="form">
            <Box
              mb={2}
              sx={{
                width: {
                  lg: pxToRem(422),
                  sm: "unset",
                },
              }}
            >
              {isOtpFieldVisible ? (
                <OTPLogin
                  errorMessage={verifyAuthState.isError ? verifyAuthState?.error?.message : null}
                  email={email}
                  handleEdit={handleEditEmail}
                  onResendClick={sendOtpRequest}
                  handleOtpSubmission={handleOtpSubmission}
                />
              ) : (
                <>
                  <Typography
                    variant="h6"
                    sx={({ breakpoints }) => ({
                      marginBottom: "0rem",
                      marginTop: pxToRem(20),
                      [breakpoints.down("md")]: {
                        fontSize: pxToRem(15),
                      },
                    })}
                  >
                    {translations.EMAIL_INPUT_LABEL}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={({ breakpoints }) => ({
                      marginBottom: ".6rem",
                      [breakpoints.down("md")]: {
                        fontSize: pxToRem(8),
                      },
                    })}
                  >
                    {translations.EMAIL_INPUT_HELPER_TEXT}
                  </Typography>
                  <Fade direction="right" in={!isOtpFieldVisible} timeout={500}>
                    <Input
                      type="email"
                      id="email-input"
                      value={email}
                      error={!!inputErrorMessage}
                      success={isEmail(email)}
                      disabled={!getProvidersState.isSuccess}
                      placeholder={translations.EMAIL_INPUT_PLACEHOLDER}
                      helperText={inputErrorMessage}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                        handleEnterKeyPress(e);
                      }}
                      fullWidth
                      onChange={(e) => handleChange(e.target.value, "email")}
                    />
                  </Fade>
                </>
              )}
              {!isOtpFieldVisible ? (
                <Box sx={{ marginTop: pxToRem(24) }}>
                  <Button
                    variant="contained"
                    color="primary"
                    id="next"
                    disabled={!email}
                    onClick={sendOtpRequest}
                    fullWidth
                  >
                    <Typography variant="button" color="white">
                      {translations.NEXT}
                    </Typography>
                  </Button>
                </Box>
              ) : null}
            </Box>
            {!isOtpFieldVisible ? (
              <Box textAlign="center">
                <Grid container justifyContent="center">
                  <Grid item xs={5}>
                    <Divider sx={{ background: "black" }} />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1" color="black" mt={1}>
                      {translations.OR}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Divider sx={{ background: "black" }} />
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center">
                  {getProvidersState.status === "fulfilled"
                    ? getProvidersState.data.providers
                        .filter((provider) => !["magic_link"].includes(provider.code))
                        .map((provider) => (
                          <Grid item key={provider.id} mt={1.5}>
                            <IconButton
                              color="primary"
                              sx={{
                                padding: 0.7,
                              }}
                              onClick={() => openSocialAuthPopup(provider)}
                            >
                              {getIcon(provider.provider)}
                            </IconButton>
                          </Grid>
                        ))
                    : [1, 2, 3, 4].map((provider) => (
                        <Grid item key={provider} mt={1.5}>
                          <IconButton
                            color="primary"
                            sx={{
                              padding: 0.7,
                            }}
                          >
                            <Skeleton variant="circular" width={25} height={25} />
                          </IconButton>
                        </Grid>
                      ))}
                </Grid>
                <Box>
                  <Typography
                    color="black"
                    variant="d7"
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("md")]: {
                        fontSize: pxToRem(8),
                      },
                    })}
                  >
                    {translations.T_N_C_AGREEMENT}
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Card>
      </BasicLayout>
    </>
  );
}

export default Login;

Login.propTypes = {
  getProvidersState: PropTypes.instanceOf(Object).isRequired,
  requestAuthState: PropTypes.instanceOf(Object).isRequired,
  verifyAuthState: PropTypes.instanceOf(Object).isRequired,

  setSelectedAuthProvider: PropTypes.func.isRequired,
  requestAuth: PropTypes.func.isRequired,
  verifyAuth: PropTypes.func.isRequired,
  openSocialAuthPopup: PropTypes.func.isRequired,
  handleClearErrorAndRetry: PropTypes.func.isRequired,
};
