import colors from "assets/theme/base/colors";

const dialogPaper = ({ functions }) => {
  const { pxToRem } = functions;
  const { grey } = colors;
  return {
    width: "auto",
    height: "auto",
    background: grey[200],
  };
};
const CloseIconButton = ({ functions }) => {
  const { pxToRem } = functions;
  return {
    position: "absolute",
    right: pxToRem(30),
    top: pxToRem(8),
  };
};

export { CloseIconButton, dialogPaper };
