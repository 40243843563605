/* eslint-disable import/no-named-as-default */
import { configureStore, isRejected } from "@reduxjs/toolkit";
import { api } from "config/apiHandler";
import cookiesManipulator from "services/browserStorage/cookies";
import authSlice from "store/slices/authSlice";
import collectionSlice from "store/slices/collectionSlice";
import generalSlice from "store/slices/generalSlice";
import surveySlice from "store/slices/surveySlice";
import userSlice from "store/slices/userSlice";

export const rtkQueryErrorLogger = () => (next) => async (action) => {
  // isRejectedWithValue Or isRejected
  if (isRejected(action)) {
    if (
      action.error?.message === "Authentication failed." ||
      action.error?.message === "Authorisation failed."
    ) {
      await cookiesManipulator.removeAuth();
    }
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    users: userSlice,
    auths: authSlice,
    generals: generalSlice,
    surveys: surveySlice,
    collections: collectionSlice,
  },
  middleware: (gDM) => gDM().concat(api.middleware).concat(rtkQueryErrorLogger),
});

export default store;
