export const STATUSES = Object.freeze({
  LOADING: "loading",
  IDLE: "idle",
  ERROR: "error",
});
export const SURVEY_STATUSES = Object.freeze({
  DRAFT: "draft",
  ONGOING: "ongoing",
  UPCOMING: "upcoming",
  COMPLETED: "completed",
});

export default STATUSES;
