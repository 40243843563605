/* eslint-disable consistent-return */
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Box } from "components";
import { getIcon } from "data/icons/icon";
import { CloseIconButton, dialogPaper } from "modules/Modal/styles";
import PropTypes from "prop-types";
import React from "react";

function Modal({
  handleClose,
  open,
  hideCloseButton,
  children,
  disableBackdropClick,
  disableEscapeKeyDown,
}) {
  const handleCloseModel = (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return null;
    }
    handleClose(event, reason);
  };

  return (
    <Box>
      <Dialog
        // disablebackdropclick={!!disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        maxWidth="auto"
        onClose={handleCloseModel}
        open={open}
        PaperProps={{
          sx: (theme) => dialogPaper(theme),
        }}
      >
        {hideCloseButton === false && (
          <Box sx={(theme) => CloseIconButton(theme)}>
            {handleClose && (
              <IconButton aria-label="close" onClick={handleClose}>
                {getIcon("close")}
              </IconButton>
            )}
          </Box>
        )}
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </Box>
  );
}
Modal.defaultProps = {
  hideCloseButton: false,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
};
Modal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  hideCloseButton: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
};
export default Modal;
