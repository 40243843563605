import { Box } from "components";
import PropTypes from "prop-types";
import React from "react";
import { useDrop } from "react-dnd";

function DroppableContainer({ children, handleDrop, type }) {
  // Extracting the 'path' prop from children
  let path;
  if (React.isValidElement(children) && children.props.path) {
    path = children.props.path;
  }
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: type,
    drop: (item, monitor) => handleDrop(item, type, path, monitor),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  let renderChildren;
  if (typeof children === "function") {
    renderChildren = children({ canDrop, isOver, type });
  } else if (React.isValidElement(children)) {
    renderChildren = React.cloneElement(children, { canDrop, isOver, type });
  } else {
    throw new Error("Children of DroppableContainer must be a function or a valid React element");
  }

  return <Box ref={dropRef}>{renderChildren}</Box>;
}

DroppableContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element, // Changed from node to element to be more precise
  ]).isRequired,
  handleDrop: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default DroppableContainer;
