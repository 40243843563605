import { ClearRounded } from "@mui/icons-material";
import { Stack, Tooltip } from "@mui/material";
import { Button } from "components";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

function TableActions({ selectedRow, onTableActionClick, disabledIDs }) {
  const [actionButtons] = useState([{ name: "delete", element: <ClearRounded /> }]);

  const handleClick = useCallback(
    async (actionType) => {
      onTableActionClick(actionType, selectedRow);
    },
    [selectedRow]
  );

  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 1, md: 1 }}>
      {actionButtons.map((item) => (
        <Tooltip
          key={item.name}
          title={disabledIDs.includes(selectedRow.id) ? null : item.name}
          placement="right"
        >
          <span>
            <Button
              disabled={disabledIDs.includes(selectedRow.id)}
              key={item.name}
              variant="text"
              fontWeight="bold"
              size="medium"
              iconOnly
              color="primary"
              onClick={() => {
                handleClick(item.name);
              }}
            >
              {item.element}
            </Button>
          </span>
        </Tooltip>
      ))}
    </Stack>
  );
}
TableActions.defaultProps = {
  disabledIDs: [],
};
TableActions.propTypes = {
  selectedRow: PropTypes.instanceOf(Object).isRequired,
  disabledIDs: PropTypes.arrayOf(PropTypes.number),
  onTableActionClick: PropTypes.func.isRequired,
};

export default TableActions;
