import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { CardMedia, Container, Grid, IconButton } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Button, Typography } from "components";
import { DefaultNavbar, PageLayout } from "modules";
import React from "react";
import { useSelector } from "react-redux";
import Footer from "views/auths/components/Footer";

const poster =
  "https://s3-alpha-sig.figma.com/img/b787/0840/d659fee45a63c74652ec9b9cb3f57158?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eSWbfJggk09zc38mGVZ0aA4ZXj25IQJuaCbw2w6e2vnIgotSi4MuqjBAq~TN0TgVxZXPZp5-fDQw~7ss5uCsjM6PMAszb2VILcVmC0cydsxQ5lFyVYI1OeWFKryi7~fhUqMpOM~YQJ67sv1yM643KRcNj2CK7k-OZ9bn-5MCFYK7VFpUaoDeJmiIhKaqD2zPgD2enVyRB-gUoq0Oo5JC0rMGlw7a6fosbav6zV3CmpTYBlj-9k9NijT1ZxHwztboLs9i525aoUUqFgujob2Q5xXIsx2EQY3gfpxwX0~Zaeml4bTRoG2sPKUuLaGcBEFC~MElZ8Vm1ROfMoLYAAsxug__";
const youTube =
  "https://s3-alpha-sig.figma.com/img/d760/6fc0/d0fa974e4a291ed661c9f124e5696ada?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hIKY62terKiIAwiNUvZvO6JtdlBoYGVorUlV326YZeZja9q1yhr~Dk088Vone6O9KEXCH4jdAsOqeHWcPBbI0Lj22j8mpiybyeQq~ipVrz21ZnnHBxtmsWz8ffmB3JI~amAvaaEQnJlZaLtU6vftZffr-fQj1~FQiNMXJTSUbeI-aMT~NEyxY815FQCoB0vccXssi4K0gJxc947n8wA1C~TQyOB0019L~8tFRXRFrQiRSp~3G~e41dl4dzfe2aK1CwXYSs88X4FK8bHeqPIzIZZCzuZK0zIMDb~ZHWOlUO83N8U-6uCCp6-juqyFY1MOCao~iqn-ovADlVEdYYK7EA__";
const Founder =
  "https://s3-alpha-sig.figma.com/img/be9e/ee14/7ea0ca759adcc5c819337904c83104ed?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Wo8o0mSlRCfte94IkWCcbWJCNW3jtTPGEGo1ybgoSHUQORmmEmMbAqAAgRWk5U8nvKVdvNG6rl07GgdDPxVyAAPDv~CMFz-MCB~1NxIkawBVk~xd9DERDDncEuPoRoQ6fMRXuLGlHnBf6dJxx2FgGV5cL~xTuAKEALpNW~hhvHCXfIQTs-fX8napJlVFuI3bBKTCU8mAszTgaR01AmWSkuChZQ1f4U8aW~sJLTXriIjenOv6DbQ~DUrn4qiD-0Fgi0O93MCQ3a5ZRR~tutJGprqfN8ZTWxpNM4cZ~d6Ur4DlnaoX-HN7nkOtRmy95Ln3BnmJXCFClms1oUqbG26dNA__";

const CoFounder =
  "https://s3-alpha-sig.figma.com/img/b07e/dca8/c4e6492c182eb6833f1a8ec289bc64d4?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bF33DpBYs1xjAnSu~5p5MegVPPbcUVOO7jRAZ3NInRwHceBV1InUixAS0KffdK~lwQBc2ddWnlM7pn6pEWWhSfZrzg5ZIE39EBEHVuzFEyQx64PR3DmF6ZlaJT3dgbqQX8o22eVPgUz2pW1b9ko6t~1pOsZ0p~gSYxMx7S-38qfaRWugATxJsv-nLs8zMOBVCYXeMxcKJNRrF~qu7ZzO4~U5xbbcfBZbbv3amj5KkCMEabT7ubzbhBWgtNs3fndHtg3JFHkIwe~YhTRuhdPRAk4NT6DposeShZfV4X6Il9N0yucEeYkP3-k5f4593hXSMo~ogxD6R3lhu7oZhWJDTw__";

const teams = [
  {
    id: 1,
    name: "John Doe",
    description: "Lorem ipsum",
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/4adb/ab01/17080b30102ee2a681ae1a05a7b4e18f?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MEHmKJuMXo5g2Ehk0pURbifk8Dy2U6vC8SGzjWMNDzwgkhd1hhoIiV626zwLCzDd8cN2UsL89z7TkDzDz7njyDdAGImDZMnH-dd~QH13zRlk-nPAFuAsDDQ8A4xvLkuGrvGdxkE8lebX-OeOSjPwaXOmgdHNTUs3RW~~60ABS6XA2gsQzybaMn~44p~Wcw0mBCKeE8txptRXhkiIBcNkKxvjDDrpsFCHD49Cc9V8ttFIog-m1s8faCRoYFm5MhoZr3GRA5zofkRqd0nWsFDJHQBsGzXKS-Sp3Z5Gx8EX2MEoXPyr8rl0D8BWOobo6XbqHWfh4nkliy-v12-XB-rUAg__",
  },
  {
    id: 2,
    name: "John Doe",
    description: "Lorem ipsum",
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/f9be/f85c/5ec89ef7fb36f45b0f696bae3c21e5af?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RzuZQ4Z96f5z1zjgGwA8imKfP5gm~t7Wpa-2ILmoqmZWThZMyqw3tWPiNucGkSyU-oa31pTLGiauiP24FRK5l1LiGfaIEtjPQ12H2W4qVY4hWMOi2uvM3mrsmwsb2ps8aW0-JnpyzYUlRWbWB2Rj0hKmmpl~d1PCiJ64fVLIWyRO0keofZEHTXReR5PuMbOxwVrLfh9gydLP2q0nROMJ-UrVQZEZV-9cbDrdGN5GP6x23IxqSntJexPnlCWFBpr02-EoZDKxIog9g5kpmIpLvqC41oIlODsQpExnlFXwxDYItlhM6dfcJlXLra-Fbqlq6dXcWziV60LnVg2Gh28Vtg__",
  },
  {
    id: 3,
    name: "John Doe",
    description: "Lorem ipsum",
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/e646/d091/8f4d366301c3fc11717d9da8d5fa21c4?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pKo2XGDwZqwcxSRhiiXlJiBW1sxbd3TYR2enkyTfnGkMl5L9xyjBO3bpEF9xSB1yslKv0Nni2lpfXAvcneTg-iv0eZTdzyf84~kIQwI4UA1czLto~SnXHFfZDwoGNN1hnbhp3lmI9dnmOH5qoX6QBEtt~J0hT0kXk-rdckIoxLQ-URT4X1wwsVE6N6kt1BgXktXAFogBgct-0lR1KVxbjlsFOvlZcQ4wEOrWrgo5pXROE~Nz6tp4qgw70~QfvzQBrvUj6Nr-ghI5yeA95YlupdLBZlOJAesljj2VAvWJ-A9D1TOm7rfgtfCxBUhnE4O2xq2eKfyCS~wxEPIokzRlZQ__",
  },
  {
    id: 4,
    name: "John Doe",
    description: "Lorem ipsum",
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/4adb/ab01/17080b30102ee2a681ae1a05a7b4e18f?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MEHmKJuMXo5g2Ehk0pURbifk8Dy2U6vC8SGzjWMNDzwgkhd1hhoIiV626zwLCzDd8cN2UsL89z7TkDzDz7njyDdAGImDZMnH-dd~QH13zRlk-nPAFuAsDDQ8A4xvLkuGrvGdxkE8lebX-OeOSjPwaXOmgdHNTUs3RW~~60ABS6XA2gsQzybaMn~44p~Wcw0mBCKeE8txptRXhkiIBcNkKxvjDDrpsFCHD49Cc9V8ttFIog-m1s8faCRoYFm5MhoZr3GRA5zofkRqd0nWsFDJHQBsGzXKS-Sp3Z5Gx8EX2MEoXPyr8rl0D8BWOobo6XbqHWfh4nkliy-v12-XB-rUAg__",
  },
  {
    id: 5,
    name: "John Doe",
    description: "Lorem ipsum",
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/f9be/f85c/5ec89ef7fb36f45b0f696bae3c21e5af?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RzuZQ4Z96f5z1zjgGwA8imKfP5gm~t7Wpa-2ILmoqmZWThZMyqw3tWPiNucGkSyU-oa31pTLGiauiP24FRK5l1LiGfaIEtjPQ12H2W4qVY4hWMOi2uvM3mrsmwsb2ps8aW0-JnpyzYUlRWbWB2Rj0hKmmpl~d1PCiJ64fVLIWyRO0keofZEHTXReR5PuMbOxwVrLfh9gydLP2q0nROMJ-UrVQZEZV-9cbDrdGN5GP6x23IxqSntJexPnlCWFBpr02-EoZDKxIog9g5kpmIpLvqC41oIlODsQpExnlFXwxDYItlhM6dfcJlXLra-Fbqlq6dXcWziV60LnVg2Gh28Vtg__",
  },
  {
    id: 6,
    name: "John Doe",
    description: "Lorem ipsum",
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/e646/d091/8f4d366301c3fc11717d9da8d5fa21c4?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pKo2XGDwZqwcxSRhiiXlJiBW1sxbd3TYR2enkyTfnGkMl5L9xyjBO3bpEF9xSB1yslKv0Nni2lpfXAvcneTg-iv0eZTdzyf84~kIQwI4UA1czLto~SnXHFfZDwoGNN1hnbhp3lmI9dnmOH5qoX6QBEtt~J0hT0kXk-rdckIoxLQ-URT4X1wwsVE6N6kt1BgXktXAFogBgct-0lR1KVxbjlsFOvlZcQ4wEOrWrgo5pXROE~Nz6tp4qgw70~QfvzQBrvUj6Nr-ghI5yeA95YlupdLBZlOJAesljj2VAvWJ-A9D1TOm7rfgtfCxBUhnE4O2xq2eKfyCS~wxEPIokzRlZQ__",
  },
];

const advisors = [
  {
    id: 1,
    name: "John Doe",
    iconUrl: <LinkedInIcon style={{ fill: "#0077b5" }} />,
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/f074/c184/4e61d3d806509cd041b7fdb480315420?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Mr4~fcOwNKczvMvPtm~WVTwLCp6Z7X~Yu1lFmeIBbPaNVFQOU~mdVCO8wJBGQmywrin9vqvBqqLydUFlfCk9QEXc8AcKi309g6nJwZqtAPztgXBo8K8CFCerTbnnSV7XbqziysJWyvzPpLSF8wSIJTnCcGVTUPJoQTwT-FddiJTryRt60VVT1a5HgrS2rNRzoyPdi64nTmLfyrt8tuyRcSzXhKP8qLfdUx66lcUJTXSB8-VC3Jlh7AGT~8toOVfinL-~hChtwJHa19i44IoSSkM00Wkog4xIngf5DClhlKWpwCi82NRnjpVPsuL4X7fcJZhZiA2-hV6Kwc7Rczr4tw__",
  },
  {
    id: 2,
    name: "John Doe",
    iconUrl: <LinkedInIcon style={{ fill: "#0077b5" }} />,
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/3c55/1bbe/200f67e9a3e1acb5c12132301dd2a26c?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=mW9AhIUiRVQXyrwDc9we60mg0Z8b5RcT~4BxNKWUjVF9R-gEhza-LPIXUqmMuGYw9nEkSlubOId1UHo2LBhLfrEXxWEFFRdw9pYekvIPoeJ4w9ldxTmNR21H6-GkiRUrvfdlLF7pCOQKyKNucftLvOY2GChowo2R2~X0Jby3TmJiFRZfvlwgrGc1UlE3XHDo62i1Fq9yZXxFAnf3t~8SAb3Zu7pxXcmhb54UVbOUGNFXPC2dZHyDxUXXPXgVNhHjBSdPuoj1dhHTqok11zrN4N5gBaHAg3xsqQzw-6hS79D5sPVtfbVA72kZK4mofJPOITsUcnw4s2tqMbU4FSxeCg__",
  },
  {
    id: 3,
    name: "John Doe",
    iconUrl: <LinkedInIcon style={{ fill: "#0077b5" }} />,
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/ba95/a673/b95feb435ae2a3f9f5533f24b7607415?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pE3rdXXQevvf0lF3buhYY9TR50f2A-sNbvuM3e98cizFXjUXopxNu95YiNoXwJ0587xNVxo4jaw6YsfmyAd62BlEGbzWgu7sSpXdXKHPZaxoxVRWSE-cEtcbzi~ogxvZail~WXLhmQ2qOmlxlRWUAPgQLCR4K06uXY-k4CqZPuLh~lFgw5Jyw7QMjMrBbPrfCKrYjgy2L6KZV9h3Y~FPiBfL4AFYDV-hf0RoPoJ330JH0HgjIaMvtrFdMhx1Wi-4dUWmMl57aOn6wevlDp4VHKbr-kC9kP8fAmH3iaJfKqFMN0jyFNMOGGLsl07xXYU8xRigpnwEhDGxuPwJ~41Tqg__",
  },
  {
    id: 4,
    name: "John Doe",
    iconUrl: <LinkedInIcon style={{ fill: "#0077b5" }} />,
    imageUrl:
      "https://s3-alpha-sig.figma.com/img/974d/e0e7/b8f746cd6c43fe01b8105870ae531cf3?Expires=1705276800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WaYX6F18xxkAeESj6yeEgWzwJ0Wg112pNKgCxP-WYcSFWfQwdzC2vE4c1pAxJLZPWiC6J8W2zcwpIvgJHavDQ8QVnGyHD~NzwvRaHZHQlza7cWFnYVjRQH4e7Puz9IVYHb9JdVCJJbsqae77G-YNmEM3d9LFYFbQJ93WoA5pP-xF29vIGJbX0JTU9Wvop9SNhCuqR4fmtyjopP8HVIGidA91~pT4UG1We-B5O-kVpQu0h29pV-ZQ5OJqfFa2hZirfc6jHi0qZV32e27FMfu94PkUQBnmNArRSxvrWDKTGkmLwCcwVRLdxPeisTAuR-6fh21mVNJGor089zMYFPGLHA__",
  },
];

function AboutUs() {
  const { translations } = useSelector((state) => state.generals);
  const navMenuList = [translations.HOW_THIS_WORKS, translations.PRICING, translations.CONTACT].map(
    (nav) => ({
      name: nav,
      route: nav.split("_").join("-").toLowerCase(),
    })
  );

  const handleClick = () => {
    window.open("https://in.linkedin.com/company/xaneai");
  };
  const handleEmailClick = () => {
    const mailtoLink = `mailto:`;
    window.open(mailtoLink);
  };
  return (
    <PageLayout>
      <DefaultNavbar transparent navMenu={navMenuList} />
      <Box
        height="calc(100vh- 56px)"
        className="flex-display flex-direction-column justify-content-between"
      >
        <Container sx={{ height: "inherit", mt: pxToRem(16) }}>
          <Box
            className="height-100 flex-display flex-direction-column"
            gap={pxToRem(80)}
            width={{ xs: "100%", md: "82%" }}
            m="auto"
          >
            <Box className="flex-display flex-direction-column" gap={pxToRem(40)}>
              <Typography
                variant="h2"
                sx={({ breakpoints }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: pxToRem(20),
                  },
                })}
              >
                Get to know our talented crew of <br />
                <strong>creators, developers,</strong> and <strong>problem solvers</strong>
              </Typography>
              <Box sx={{ height: 420, width: "100%" }}>
                <CardMedia
                  component="img"
                  image={poster}
                  title="Poster Image"
                  sx={{ objectFit: "cover", height: "100%", width: "100%" }}
                />
              </Box>
              <Box
                className="flex-display"
                gap={pxToRem(16)}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("md")]: {
                    flexDirection: "column",
                  },
                })}
              >
                <Typography variant="h4" className="width-100">
                  <strong>About Us</strong>
                </Typography>
                <Typography variant="subtitle2" sx={{ textAlign: "justify" }}>
                  Founded in 2017, Xane AI is an award winning B2B SaaS AI platform aimed at
                  revolutionizing user experience using Computer Vision and Natural Language
                  Processing to help organizations in creating personalized user experiences along
                  with automating their business processes eventually helping them reducing cost &
                  increasing business efficiency.
                </Typography>
                <Typography variant="subtitle2">
                  Within 2 years of being operational, Xane AI has a portfolio that spans across
                  various industries such as automobile, FMCG, healthcare, banking and
                  entertainment. Our consumer insights are helping companies across countries like
                  India, Netherlands, Bulgaria, Romania, Africa, Kuwait, US, UK and Philippines.
                </Typography>
              </Box>
            </Box>
            <Box height={pxToRem(420)}>
              <CardMedia
                component="img"
                image={youTube}
                title="Poster Image"
                height="100%"
                width="100%"
                objectFit="cover"
                sx={({ breakpoints }) => ({
                  [breakpoints.down("md")]: {
                    objectFit: "fill",
                    height: "100%",
                    width: "100%",
                  },
                })}
              />
            </Box>
            <Box className="flex-display flex-direction-column" gap={pxToRem(32)}>
              <Typography variant="h6">About our Teams</Typography>
              <Box className="flex-display flex-direction-column" gap={pxToRem(32)}>
                <Box
                  className="flex-display"
                  gap={pxToRem(32)}
                  sx={({ breakpoints }) => ({
                    [breakpoints.down("md")]: {
                      flexDirection: "column",
                    },
                  })}
                >
                  <Box className="flex-display flex-direction-column" gap={pxToRem(16)}>
                    <Box sx={{ height: 248 }}>
                      <CardMedia
                        component="image"
                        image={Founder}
                        title="Poster Image"
                        sx={{
                          objectFit: "fill",
                          height: "100%",
                          width: "100%",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                    <Box
                      className="flex-display"
                      sx={({ breakpoints }) => ({
                        [breakpoints.down("md")]: {
                          gap: pxToRem(16),
                          flexDirection: "column",
                        },
                      })}
                    >
                      <Typography variant="subtitle1" className="width-100">
                        <strong>Ayush Jain</strong> <br />{" "}
                        <Typography variant="caption">Founder & CEO</Typography>
                      </Typography>
                      <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                        Ayush Jain started this venture just after completing his graduation from
                        IIT Delhi in May 2017. He has bootstrapped this venture from the development
                        of the product to selling it to organizations and getting first set of
                        paying clients. He believes that Persistence and Hard work which are two of
                        the most significant traits that are required to build great things.
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="flex-display flex-direction-column" gap={pxToRem(16)}>
                    <Box sx={{ height: 248 }}>
                      <CardMedia
                        component="image"
                        image={CoFounder}
                        title="Poster Image"
                        sx={{
                          objectFit: "fill",
                          height: "100%",
                          width: "100%",
                          borderRadius: pxToRem(8),
                        }}
                      />
                    </Box>
                    <Box
                      className="flex-display"
                      sx={({ breakpoints }) => ({
                        [breakpoints.down("md")]: {
                          gap: pxToRem(16),
                          flexDirection: "column",
                        },
                      })}
                    >
                      <Typography variant="subtitle1" className="width-100">
                        <strong> Sahil Narain </strong> <br />{" "}
                        <Typography variant="caption">Co-Founder & CTO</Typography>
                      </Typography>
                      <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
                        Ayush Jain started this venture just after completing his graduation from
                        IIT Delhi in May 2017. He has bootstrapped this venture from the development
                        of the product to selling it to organizations and getting first set of
                        paying clients. He believes that Persistence and Hard work which are two of
                        the most significant traits that are required to build great things.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {teams.map((item) => (
                      <Grid item xs={6} md={4} key={item.id}>
                        <Box sx={{ height: 189, mb: "16px" }}>
                          <CardMedia
                            component="image"
                            image={item.imageUrl}
                            title="Poster Image"
                            sx={{
                              objectFit: "fill",
                              height: "100%",
                              width: "100%",
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                        <Typography variant="subtitle1" className="text-align-center">
                          <strong> {item.name} </strong> <br />{" "}
                          <Typography variant="caption">{item.description}</Typography>
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box className="flex-display flex-direction-column" gap={pxToRem(32)}>
              <Typography variant="h6">Our Advisors</Typography>
              <Box className="flex-display flex-direction-column" gap={pxToRem(32)}>
                <Box>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {advisors.map((advisor) => (
                      <Grid item xs={6} md={3} key={advisor.id}>
                        <Box sx={{ height: 248, mb: "16px" }}>
                          <CardMedia
                            component="image"
                            image={advisor.imageUrl}
                            title="Poster Image"
                            sx={{
                              objectFit: "fill",
                              height: "100%",
                              width: "100%",
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                        <Box className="flex-container-column">
                          <Typography variant="subtitle1">
                            <strong> {advisor.name} </strong>
                          </Typography>
                          <IconButton onClick={handleClick}>{advisor.iconUrl}</IconButton>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              className="flex-display flex-direction-column align-items-center"
              gap={pxToRem(32)}
              mb={pxToRem(32)}
            >
              <Box
                className="flex-display flex-direction-column text-align-center"
                gap={pxToRem(8)}
              >
                <Typography variant="h4">
                  <strong>Come work with us!</strong>
                </Typography>
                <Typography variant="subtitle1">
                  Write to us, tell us why you want to work with us and send your resume.
                </Typography>
              </Box>
              <Box>
                <Button onClick={handleEmailClick}>Email Us</Button>
              </Box>
            </Box>
          </Box>
        </Container>
        <Footer />
      </Box>
    </PageLayout>
  );
}
export default AboutUs;
