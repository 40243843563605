import { Box, Typography } from "components";
import PropTypes from "prop-types";
import React from "react";

const progressBar = (_theme, { progressBarStyle }) => ({
  bgcolor: "white.main",
  border: "1px solid",
  borderColor: "black.main",
  borderRadius: "8px",
  height: "8px",
  width: "189px",
  ...progressBarStyle,
});

const rectangle = (_theme, { rectangleStyle }) => ({
  bgcolor: "black.main",
  border: "1px solid",
  borderColor: "black.main",
  borderRadius: "8px",
  height: "8px",
  left: "-1px",
  position: "relative",
  top: "-1px",
  width: "95px",
  ...rectangleStyle,
});

export function ProgressBar({ progressBarStyle, rectangleStyle }) {
  return (
    <Box className="progress-bar" sx={(theme) => progressBar(theme, { progressBarStyle })}>
      <Box className="rectangle" sx={(theme) => rectangle(theme, { rectangleStyle })} />
    </Box>
  );
}

ProgressBar.propTypes = {
  rectangleStyle: PropTypes.shape({
    borderColor: PropTypes.string,
    width: PropTypes.string,
  }).isRequired,
  progressBarStyle: PropTypes.shape({
    alignSelf: PropTypes.string,
    minWidth: PropTypes.string,
    position: PropTypes.string,
    width: PropTypes.string,
  }).isRequired,
};

const container = () => ({
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
});
const box = (theme) => {
  const { functions } = theme;

  const { pxToRem } = functions;
  return {
    border: "0px none",
    minHeight: pxToRem(50),
    height: "auto",
    width: pxToRem(226),
    alignItems: "flex-start",
    borderColor: "black.main",
    borderTopStyle: "solid",
    borderTopWidth: pxToRem(0.25),
    display: "flex",
    flexDirection: "column",
    gap: pxToRem(8),
    left: "0",
    padding: "24px 24px 32px",
    marginBottom: pxToRem(60),
  };
};
const text = (theme) => {
  const { typography } = theme;
  const { size } = typography;
  return {
    color: "black.main",
    fontSize: size.xs,
    lineHeight: "normal",
    position: "relative",
  };
};

export function Footer() {
  return (
    <Box className="box" sx={(theme) => container(theme)}>
      <Box className="plan-and-responses-wrapper" sx={(theme) => box(theme)}>
        <Typography variant="subtitle2" component="div" sx={(theme) => text(theme)}>
          Free Plan
        </Typography>
        <Typography variant="body2" component="div" sx={(theme) => text(theme)}>
          Number of responses left
        </Typography>
        <ProgressBar
          rectangleStyle={{
            borderColor: "unset",
            width: "100px",
          }}
          progressBarStyle={{
            alignSelf: "stretch",
            minWidth: "178px",
            position: "relative",
            width: "unset",
          }}
        />
        <Typography variant="body2" component="div" sx={(theme) => text(theme)}>
          5/10
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className="need-more-responses"
          sx={(theme) => text(theme)}
        >
          <span className="span">
            Need more responses? <br />
          </span>
          <span className="text-wrapper-3">Buy now</span>
          <span className="text-wrapper-4">.</span>
        </Typography>
      </Box>
    </Box>
  );
}
