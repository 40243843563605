import { createSlice } from "@reduxjs/toolkit";
import cookiesManipulator from "services/browserStorage/cookies";

const initialState = {
  user: cookiesManipulator.getAuth().user,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      cookiesManipulator.setCookies("user", action.payload);
    },
  },
  extraReducers: () => {},
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
