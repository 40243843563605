import { Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function index({ menuList, onSelectItem, anchorEl, open, handleClose, selectedItem }) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiMenu-paper": {
          width: "inherit",
          boxShadow: "none",
          border: "1px solid",
          mt: "10px",
        },
      }}
    >
      {menuList.map((item) => (
        <MenuItem
          data-testid={item.code}
          selected={selectedItem && selectedItem.code === item.code}
          key={item.code}
          onClick={() => {
            onSelectItem(item);
          }}
          sx={(theme) => {
            const { typography } = theme;
            const { fontWeightRegularLight, size } = typography;
            return {
              fontSize: size.xs,
              fontWeight: fontWeightRegularLight,
            };
          }}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}

index.defaultProps = {
  anchorEl: null,
  selectedItem: null,
};

index.propTypes = {
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedItem: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  onSelectItem: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // An HTML element, or a function that returns one. It's used to set the position of the menu.
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default index;
