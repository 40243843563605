import pxToRem from "assets/theme/functions/pxToRem";
import { Alert, Box, Button, Input, Loader, Typography } from "components";
import { Modal } from "modules";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const USER_INITIAL_STATE = {
  id: "",
  name: "",
  photo_url: "",
  email: "",
  role: "",
};
function ProfileDetailsModal({ userQueryState, handleProfileUpdate, updateUserState }) {
  const [userData, setUserData] = useState(USER_INITIAL_STATE);
  const [open, setOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (userQueryState.status === "fulfilled") {
      const { user } = userQueryState.data;
      if (user.first_name === null) {
        setOpen(true);
      }
    }
  }, [userQueryState.status]);

  const toggleModal = () => {
    setOpen((prevProp) => !prevProp);
  };

  const onUserDataChange = (e) => {
    const { name, value } = e.target;

    setShowError(false);
    setUserData((_userData) => ({
      ..._userData,
      [name]: value,
    }));
  };
  const handleNext = async () => {
    setShowError(true);

    if (userData.name) {
      const [firstName, lastName] = userData.name.trim().split(" ");
      const payload = {
        id: userData.id,
        first_name: firstName,
        last_name: lastName || "",
      };
      setShowError(false);
      const isUpdated = await handleProfileUpdate(payload);
      if (isUpdated) toggleModal();
    }
  };

  return (
    <Box>
      <Modal
        open={open}
        hideCloseButton
        handleClose={toggleModal}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Alert
          alertType="error"
          toggle={updateUserState.isError}
          handleCloseError={updateUserState.reset}
          duration={5000}
        >
          {updateUserState.isError ? updateUserState?.error.message : ""}
        </Alert>
        <Box
          className="flex-display flex-direction-column"
          gap={pxToRem(24)}
          width={pxToRem(320)}
          padding={pxToRem(10)}
        >
          <Typography variant="h4">What should we call you?</Typography>
          <Box className="flex-display flex-direction-column" gap={pxToRem(16)}>
            <Typography variant="h6">Full Name*</Typography>
            <Input
              name="name"
              type="text"
              size="small"
              placeholder="Type your name"
              value={userData.name}
              error={showError && !userData.name}
              onChange={onUserDataChange}
            />
          </Box>
          <Box className="flex-display flex-direction-column" gap={pxToRem(16)}>
            <Typography variant="h6">Organization Name</Typography>
            <Input
              name="organization"
              size="small"
              type="text"
              placeholder="Enter organization name"
              value={userData.organization}
              error={showError && !userData.organization}
              onChange={onUserDataChange}
            />
          </Box>
          <Box>
            <Loader toggle={updateUserState.status === "pending"} />
            <Button fullWidth onClick={handleNext}>
              Next
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

ProfileDetailsModal.propTypes = {
  userQueryState: PropTypes.instanceOf(Object).isRequired,
  updateUserState: PropTypes.instanceOf(Object).isRequired,
  handleProfileUpdate: PropTypes.func.isRequired,
};

export default ProfileDetailsModal;
