import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "config/apiHandler";

export const updateRecentlyUsedSuggestion = createAsyncThunk(
  "collections/updateRecentlyUsedSuggestion",
  async ({ type, item }, { dispatch }) => {
    const queryMap = {
      questions: "getQuestions",
      reactions: "getReactions",
      options: "getOptions",
    };

    const queryType = queryMap[type];

    if (!queryType) {
      console.error(`Unsupported payload type: ${type}`);
      return;
    }

    dispatch(
      api.util.updateQueryData(queryType, undefined, (draft) => {
        const { reference } = item[0];
        const index = draft[type].findIndex((obj) => obj.reference === reference);
        if (index !== -1) {
          draft[type][index].recentlyUsedDate = new Date().getTime();
        }
      })
    );
  }
);

const initialState = {
  currentBankSuggestion: {
    type: "question",
    length: 1,
  },
  groupEnabled: true,
  showCustomSuggestionBar: false,
  suggestionToEdit: null,
};

export const collectionSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    setCurrentBankSuggestion(state, action) {
      state.currentBankSuggestion = action.payload;
    },

    setGroupEnabled(state, action) {
      state.groupEnabled = action.payload;
    },
    setShowCustomSuggestionBar(state, action) {
      state.showCustomSuggestionBar = action.payload;
    },
    setSuggestionToEdit(state, action) {
      state.suggestionToEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateRecentlyUsedSuggestion.fulfilled, () => {});
  },
});

export const {
  setCurrentBankSuggestion,
  setGroupEnabled,
  setShowCustomSuggestionBar,
  setSuggestionToEdit,
} = collectionSlice.actions;

export default collectionSlice.reducer;
