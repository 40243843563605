import { api } from "config/apiHandler";
import { setUser } from "store/slices/userSlice";

const users = api.injectEndpoints({
  tagTypes: ["User"],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `/users/${id}`,
      providesTags: (result) => (result ? [{ type: "User", id: result.id }] : ["User"]),
      transformResponse: async (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.user.role.id === 1) dispatch(setUser(data.user));
        } catch (error) {
          console.error(error);
          // Handle error
        }
      },
    }),
    createUser: build.mutation({
      query: (requestBody) => ({
        url: `/users`,
        method: "POST",
        body: requestBody,
      }),
      transformResponse: async (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
    updateUser: build.mutation({
      query: (requestBody) => ({
        url: `users/${requestBody.id}`,
        method: "PUT",
        body: requestBody,
      }),
      invalidatesTags: (result) => (result ? [{ type: "User", id: result.id }] : ["User"]),
      transformResponse: async (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.user.role.id === 1) dispatch(setUser(data.user));
        } catch (error) {
          // Handle error
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
} = users;
