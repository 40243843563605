import { Slide } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Button, Typography } from "components";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { OTPInput, ResendOTP } from "services/customPackages/OTPInput";

function OTPLogin({ email, handleEdit, onResendClick, handleOtpSubmission, errorMessage }) {
  const { translations } = useSelector((state) => state.generals);
  const [OTP, setOTP] = useState("");
  const [OTPLength] = useState(4);

  const handleOTPInput = (value) => {
    if (OTP === value) return;
    setOTP(value);
    if (value.length === OTPLength) {
      handleOtpSubmission(value);
    }
  };
  const renderButton = (buttonProps) => (
    <Button {...buttonProps} variant="text" sx={{ textTransform: "none", color: "primary.main" }}>
      {translations.RESEND}&nbsp;
      <span style={{ color: "black" }}>
        {buttonProps.remainingTime !== 0 &&
          ` ${translations.IN} ${buttonProps.remainingTime} ${translations.SEC}`}
      </span>
    </Button>
  );

  return (
    <Box
      mb={2}
      mt={1}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.preventDefault();
          handleEdit();
        }
      }}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        gap: "2px",
      }}
    >
      <Box
        sx={{
          width: "inherit",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Slide direction="right" in={!!email} timeout={300}>
          <Typography
            variant="body2"
            marginTop={pxToRem(15)}
            sx={({ breakpoints }) => ({
              [breakpoints.down("md")]: {
                fontSize: pxToRem(12),
              },
            })}
          >
            {translations.ENTER_CODE_SENT_TO} {email}
          </Typography>
        </Slide>
        <Box
          sx={{
            width: {
              lg: "75%",
              md: "100%",
              xs: "100%",
              sm: "100%",
            },
            display: "flex",
            marginTop: pxToRem(6),
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <OTPInput
            value={OTP}
            onChange={handleOTPInput}
            autoFocus
            OTPLength={OTPLength}
            otpType="number"
            error={!!(errorMessage && OTP.length === OTPLength)}
            style={{
              display: "flex",
              width: "inherit",
              flexDirection: "row",
              justifyContent: "space-between",
              userSelect: "none",
            }}
            inputStyles={{
              width: "52px",
              height: "48px",
              marginRight: "unset",
              // border: "1px solid",
              // borderRadius: "8px",
              // borderColor:
              //   errorMessage && OTP.length === OTPLength ? colors.error.main : colors.grey[500],
              // outline: "none",
              // ":focus-visible": {
              //   outline: "red auto 1px",
              // },
              userSelect: "none",
              background: "transparent",
            }}
          />
        </Box>
        {!!(errorMessage && OTP.length === OTPLength) && (
          <Typography color="error" variant="caption" sx={{ mt: 1 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>

      <Box sx={{ width: "inherit" }}>
        <ResendOTP
          onResendClick={() => {
            setOTP("");
            onResendClick();
          }}
          renderButton={renderButton}
          renderTime={() => React.Fragment}
          maxTime={15}
          style={{
            marginTop: pxToRem(20),
            display: "flex",
            flexDirection: "row",
          }}
        />
      </Box>
    </Box>
  );
}

export default OTPLogin;

OTPLogin.defaultProps = {
  errorMessage: null,
};

OTPLogin.propTypes = {
  email: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  onResendClick: PropTypes.func.isRequired,
  handleOtpSubmission: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};
