import { createSlice } from "@reduxjs/toolkit";
import cookiesManipulator from "services/browserStorage/cookies";
import { readFromLocalStorage, writeToLocalStorage } from "services/browserStorage/localStorage";

const initialState = {
  sessionState: readFromLocalStorage("sessionState"),
  selectedProvider: readFromLocalStorage("selectedProvider"),
};

export const authSlice = createSlice({
  name: "auths",
  initialState,
  reducers: {
    setSessionState(state, action) {
      writeToLocalStorage("sessionState", action.payload);
      state.sessionState = action.payload;
    },
    setSelectedProvider(state, action) {
      writeToLocalStorage("selectedProvider", action.payload);
      state.selectedProvider = action.payload;
    },

    setAuthSession(_state, action) {
      cookiesManipulator.setAuth(action.payload);
    },
    removeAuthSession() {
      cookiesManipulator.removeAuth();
      localStorage.removeItem("sessionState");
      localStorage.removeItem("email");
    },
  },
  extraReducers: () => {},
});

// Action creators are generated for each case reducer function
export const { setSelectedProvider, setSessionState, setAuthSession, removeAuthSession } =
  authSlice.actions;

export default authSlice.reducer;
