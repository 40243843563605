import { api } from "config/apiHandler";
import convertToLabelValuePairs from "services/utils/convertToLabelValuePairs";

const meta = api.injectEndpoints({
  tagTypes: ["meta"],
  endpoints: (build) => ({
    getMeta: build.query({
      query: () => `/meta`,
      providesTags: ["meta"],
      transformResponse: async (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          const { metadata } = baseQueryReturnValue.data;
          const _metadata = {};
          _metadata.categories = convertToLabelValuePairs(metadata.categories, "category", "id");
          _metadata.surveyTypes = convertToLabelValuePairs(metadata.interfaces, "interface", "id");
          _metadata.mediums = convertToLabelValuePairs(metadata.mediums, "mediums", "id");
          _metadata.answerTypes = convertToLabelValuePairs(
            metadata.answer_types,
            "answer_type",
            "id"
          );
          return { metadata: _metadata };
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),
  }),
  overrideExisting: false,
});
const { useGetMetaQuery } = meta;

export default useGetMetaQuery;
