import {
  Collapse,
  Divider,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Box, Typography } from "components";
import { setMiniSidenav, useUIController } from "context";
import SidenavCollapse from "modules/Sidenav/SidenavCollapse";
import SidenavRoot from "modules/Sidenav/SidenavRoot";
import {
  divider,
  sidenavMenu,
  sidenavOptions,
  sidenavToggle,
} from "modules/Sidenav/styles/sidenav";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";

import { Footer } from "./Footer";
import {
  collapseIcon,
  collapseIconBox,
  collapseItem,
  collapseText,
} from "./styles/sidenavCollapse";

function Sidenav({ color, routes, ...rest }) {
  const [open, setOpen] = useState(false);
  const [controller, dispatch] = useUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();

  const collapseName = location.pathname;

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const isActive = (key, name) => {
    const currentPath = `${location.pathname}${location.hash ? location.hash : ""}`;

    return key === currentPath;
  };
  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes
    .filter((route) => !route.HiddenOnSidebar)
    .map(({ type, name, icon, title, noCollapse, key, href, route, collapse, subRoutes }) => {
      let returnValue;
      if (type === "collapse") {
        if (route) {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={isActive(key)}
                noCollapse={noCollapse}
              />
            </Link>
          ) : (
            <Box key={key}>
              <NavLink to={`app${route}`}>
                <SidenavCollapse name={name} icon={icon} active={isActive(key)} />
              </NavLink>
              {miniSidenav
                ? null
                : subRoutes && (
                    <TransitionGroup>
                      {subRoutes.map((item) => (
                        <Collapse key={item.key}>
                          <NavLink key={item.key} to={item.key}>
                            <SidenavCollapse
                              subRoutes
                              name={item.name}
                              icon={item.icon}
                              active={isActive(item.key)}
                            />
                          </NavLink>
                        </Collapse>
                      ))}
                    </TransitionGroup>
                  )}
              <Divider
                light={!(transparentSidenav || whiteSidenav)}
                variant="middle"
                sx={(theme) => divider(theme, { miniSidenav })}
              />
            </Box>
          );
        } else {
          returnValue = (
            <Box>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={isActive(key)}
                collapse
                open={open}
                textColor={textColor}
                onClick={() => setOpen(!open)}
              />

              <TransitionGroup>
                {collapse.map((item) =>
                  open ? (
                    <Collapse key={item.key}>
                      <NavLink key={item.key} to={item.route}>
                        <SidenavCollapse
                          name={item.name}
                          icon={item.icon}
                          active={isActive(item.key)}
                        />
                      </NavLink>
                    </Collapse>
                  ) : null
                )}
              </TransitionGroup>
            </Box>
          );
        }
      }

      return returnValue;
    });

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode, color }}
    >
      <Box sx={(theme) => sidenavMenu(theme)}>
        <Box sx={(theme) => sidenavToggle(theme, { miniSidenav })}>
          <IconButton size="small" disableRipple color={textColor} onClick={handleMiniSidenav}>
            <Icon fontSize="medium">{miniSidenav ? "menu" : "menu_open"}</Icon>
          </IconButton>
        </Box>
        <Box pt={3} pb={1} px={4} textAlign="center" ml="auto">
          <Box
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="h6" color="secondary">
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </Typography>
          </Box>
        </Box>
        <List sx={(theme) => sidenavOptions(theme, { miniSidenav })}>{renderRoutes}</List>
      </Box>
      {!miniSidenav && <Footer />}
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
