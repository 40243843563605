export function sidenavMenu() {
  return {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "inherit",
  };
}

export function sidenavToggle(theme, { miniSidenav }) {
  const { functions } = theme;

  const { pxToRem } = functions;

  return {
    color: "inherit",
    display: "flex",
    justifyContent: miniSidenav ? "center" : "flex-start",
    flexDirection: "row",
    width: miniSidenav ? "inherit" : pxToRem(178),
    height: "24px",
  };
}
export function sidenavOptions() {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "inherit",
  };
}
export function divider(theme, { miniSidenav }) {
  return {
    width: miniSidenav ? "70%" : "174px",
    margin: "0 auto",
    height: "0.25px",
  };
}
