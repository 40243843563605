import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useVerifyTeamInviteMutation } from "store/services/teamsApi";
import InvitesView from "views/auths/Invites";

function Invites() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [inviteData, setInviteData] = useState(null);
  const [verifyTeamInvite, verifyTeamInviteState] = useVerifyTeamInviteMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location && location.search) {
      const signature = searchParams.get("signature");
      const decodedString = atob(signature);

      const urlSearchParams = new URLSearchParams(decodedString);
      const inviteDataTemp = Object.fromEntries(urlSearchParams);
      setInviteData({ ...inviteDataTemp, last_name: inviteDataTemp.last_name || "" });
    }
  }, []);

  const acceptTeamInvite = async (requestBody) => {
    try {
      await verifyTeamInvite(requestBody).unwrap();
      navigate("/app/surveys");
    } catch (error) {
      if (error.message) toast.error(error.message, { position: "bottom-left" });
    }
  };

  return (
    <InvitesView
      inviteData={inviteData}
      acceptTeamInvite={acceptTeamInvite}
      verifyTeamInviteState={verifyTeamInviteState}
    />
  );
}

export default Invites;
