import { Box, Button, Loader, Typography } from "components";
import { PageLayout } from "modules";
import PropTypes from "prop-types";
import React from "react";

function Verify({ verifyAuthState, getAuthSessionState, onRetryClick }) {
  return (
    <PageLayout>
      <Loader toggle={verifyAuthState.isLoading || getAuthSessionState.isLoading} />
      {verifyAuthState.isError || getAuthSessionState.isError ? (
        <Box
          coloredShadow="error"
          borderRadius="md"
          className="width-50 flex-container-column margin-auto"
          sx={{
            padding: 1,
            marginTop: "24%",
          }}
        >
          <Typography variant="h3" color="error" textAlign="center">
            {verifyAuthState.error?.message || getAuthSessionState.error?.message}
          </Typography>

          <Button variant="text" color="info" onClick={onRetryClick}>
            try again
          </Button>
        </Box>
      ) : null}
    </PageLayout>
  );
}

Verify.propTypes = {
  getAuthSessionState: PropTypes.instanceOf(Object).isRequired,
  verifyAuthState: PropTypes.instanceOf(Object).isRequired,

  onRetryClick: PropTypes.func.isRequired,
};
export default Verify;
