import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  survey: null,
  questionnaire: [],
  templates: [],
  surveys: [],
  deletedSurveys: [],
  showDraftError: false,
  draftErrors: [],
  showQuickSetting: false,
  surveyClone: null,
};

export const surveySlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    setShowDraftError(state, action) {
      state.showDraftError = action.payload;
    },
    setDraftErrors(state, action) {
      state.draftErrors = action.payload;
    },
    setShowQuickSetting(state, action) {
      state.showQuickSetting = action.payload;
    },
    setSurveyClone(state, action) {
      state.surveyClone = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShowDraftError, setDraftErrors, setShowQuickSetting, setSurveyClone } =
  surveySlice.actions;

export default surveySlice.reducer;
