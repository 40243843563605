// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import { ReactComponent as KaiFormLogo } from "assets/images/KaiFormLogo.svg";
//  base styles
import breakpoints from "assets/theme/base/breakpoints";
import pxToRem from "assets/theme/functions/pxToRem";
//  components
import Box from "components/Box";
import Button from "components/Button";
//  context
import { useUIController } from "context";
import resources from "data/resources";
// import Button from "components/Button";
// modules
import DefaultNavbarLink from "modules/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "modules/Navbars/DefaultNavbar/DefaultNavbarMobile";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// react-router components
import { Link } from "react-router-dom";

function DefaultNavbar({ transparent, light, action, navMenu }) {
  const [controller] = useUIController();
  const { darkMode } = controller;
  const { locale } = controller;
  const { HOW_THIS_WORK, PRICING, CONTACT_US } = resources[locale].translation;

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Box
      sx={{
        height: pxToRem(56),
        background: (theme) => theme.palette.white.main,
      }}
    >
      <Container sx={{ height: "inherit" }}>
        <Box
          py={1}
          height="inherit"
          px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 16 : 2 }}
          my={0}
          mx={0}
          width="inherit"
          borderRadius="lg"
          shadow={transparent ? "none" : "md"}
          color={light ? "white" : "dark"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="absolute"
          left={0}
          zIndex={3}
          sx={({
            palette: { transparent: transparentColor, white, background },
            functions: { rgba },
          }) => ({
            backgroundColor: transparent
              ? transparentColor.main
              : rgba(darkMode ? background.sidenav : white.main, 0.8),
            backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
          })}
        >
          <Box component={Link} to="/auths/login" py={0} lineHeight={0} pl={{ xs: 0, lg: 0 }}>
            <KaiFormLogo />
          </Box>
          <Box color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
            {navMenu.map((nav) => (
              <DefaultNavbarLink name={nav.name} route={nav.route} light={light} key={nav.name} />
            ))}
          </Box>
          {action &&
            (action.type === "internal" ? (
              <Box display={{ xs: "none", lg: "inline-block" }}>
                <Button
                  component={Link}
                  to={action.route}
                  variant="gradient"
                  color={action.color ? action.color : "info"}
                  size="small"
                >
                  {action.label}
                </Button>
              </Box>
            ) : (
              <Box display={{ xs: "none", lg: "inline-block" }}>
                <Button
                  component="a"
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  color={action.color ? action.color : "info"}
                  size="small"
                  sx={{ mt: -0.3 }}
                >
                  {action.label}
                </Button>
              </Box>
            ))}
          <Box
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color="inherit"
            sx={{ cursor: "pointer" }}
            onClick={openMobileNavbar}
          >
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
          </Box>
        </Box>
        {mobileView && (
          <DefaultNavbarMobile
            open={mobileNavbar}
            close={closeMobileNavbar}
            navMenu={navMenu}
            light={light}
          />
        )}
      </Container>
    </Box>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
  navMenu: [],
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  navMenu: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
