import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "assets/theme";
import Invites from "containers/auths/Invites";
import LoginContainer from "containers/auths/Login";
import VerifyContainer from "containers/auths/Verify";
import FAQs from "containers/static/FAQs";
import PrivacyPolicy from "containers/static/PrivacyPolicy";
import { useUIController } from "context";
import { DashboardNavbar, ProfileDetailsModal, Sidenav } from "modules";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes, { RequireAuth } from "routes/routes";
import cookiesManipulator from "services/browserStorage/cookies";
import { ConfirmationServiceProvider } from "services/customHooks/useConfirmation";
import { useLogoutMutation } from "store/services/authsApi";
import useGetMetaQuery from "store/services/metaApi";
import { useLazyGetUserQuery, useUpdateUserMutation } from "store/services/usersApi";
import AboutUs from "views/static/AboutUs";

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [getUserData, userDataState] = useLazyGetUserQuery();
  const [updateUser, updateUserState] = useUpdateUserMutation();
  const [logout] = useLogoutMutation();
  const [controller] = useUIController();
  const { sidenavColor, darkMode } = controller;

  const { pathname } = useLocation();
  useGetMetaQuery();

  useEffect(() => {
    const fetchInitials = async () => {
      const isUserAuthenticated = await cookiesManipulator.getAuth().token;
      if (isUserAuthenticated) getUserData("me");
    };
    fetchInitials();
  }, [cookiesManipulator.getAuth().token]);

  const handleProfileUpdate = async (payload) => {
    try {
      await updateUser(payload).unwrap();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleLogout = async () => {
    const payload = { active: 0 };
    try {
      await logout(payload).unwrap();
      navigate("/auths/login");
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes, parentRoute = "") =>
    allRoutes.flatMap((route) => {
      const { route: currentRoute, layout, component, subRoutes } = route;
      const fullRoute = parentRoute + currentRoute;
      const routePath = layout + fullRoute;

      const routeComponent = component && (
        <Route
          exact
          path={routePath}
          element={<RequireAuth>{component}</RequireAuth>}
          key={route.key}
        />
      );

      if (subRoutes && subRoutes.length > 0) {
        const subRoutesComponents = getRoutes(subRoutes, fullRoute);

        return [routeComponent, ...subRoutesComponents];
      }

      if (route.fragmentIdentifier) {
        const fragmentPath = `${routePath}#${route.fragmentIdentifier}`;
        const fragmentComponent = component && (
          <Route
            exact
            path={fragmentPath}
            element={<RequireAuth>{component}</RequireAuth>}
            key={`${route.key}-fragment`}
          />
        );

        return [routeComponent, fragmentComponent];
      }

      return routeComponent;
    });

  const hideSidenavOnTheseRoutes = ["templates", "preview", "builder", "auths"];

  function shouldHideSidebar(currentRoute) {
    return !hideSidenavOnTheseRoutes.some((route) => currentRoute.includes(route));
  }

  return (
    <ThemeProvider theme={darkMode ? theme : theme}>
      <CssBaseline />
      <ConfirmationServiceProvider>
        {shouldHideSidebar(location.pathname) && cookiesManipulator.getAuth().token && (
          <>
            <DashboardNavbar handleLogout={handleLogout} userData={userDataState} />
            <Sidenav
              color={sidenavColor}
              brand="https://dashboard.surveys.xane.ai/static/media/finalLogoXaneAi.42cdabfc.png"
              routes={routes}
            />

            <ProfileDetailsModal
              userQueryState={userDataState}
              updateUserState={updateUserState}
              handleProfileUpdate={handleProfileUpdate}
            />
          </>
        )}
        <Toaster
          position="bottom-right"
          toastOptions={{
            duration: 4000,
            success: {
              position: "bottom-right",
              style: {
                background: theme.palette.background.default,
                color: theme.palette.primary.main,
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
              },
              iconTheme: {
                primary: theme.palette.success.main,
                secondary: theme.palette.background.default,
              },
            },
            error: {
              style: {
                background: theme.palette.background.default,
                color: theme.palette.primary.main,
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
              },
              iconTheme: {
                primary: theme.palette.error.main,
                secondary: theme.palette.background.default,
              },
            },
          }}
        />

        <Routes>
          {getRoutes(routes)}
          <Route
            path="/auths/login"
            element={
              cookiesManipulator.getAuth().token ? (
                <Navigate to="/app/surveys" />
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            path="/auths/verify"
            element={
              cookiesManipulator.getAuth().token ? (
                <Navigate to="/app/surveys" />
              ) : (
                <VerifyContainer />
              )
            }
          />
          <Route
            path="/invites"
            element={
              cookiesManipulator.getAuth().token ? <Navigate to="/app/surveys" /> : <Invites />
            }
          />
          <Route path="*" element={<Navigate to="/auths/login" />} />
          <Route path="/static/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/static/faq" element={<FAQs />} />
          <Route path="/static/about-us" element={<AboutUs />} />
        </Routes>
      </ConfirmationServiceProvider>
    </ThemeProvider>
  );
}
