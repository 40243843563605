import { Card } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { Box, Button, Input, Loader, Typography } from "components";
import PropTypes from "prop-types";
import BasicLayout from "views/auths/components/BasicLayout";

function Invites({ inviteData, acceptTeamInvite, verifyTeamInviteState }) {
  return (
    <BasicLayout navMenu={[]}>
      <Card
        className="flex-container-column align-items-start overflow-hidden box-shadow justify-content-between position-relative"
        sx={{
          height: pxToRem(440),
          padding: "4rem 3rem",
          width: {
            lg: pxToRem(508),
            sm: "unset",
          },
          gap: 2,
        }}
      >
        <Box className="centered">
          <Box width="100%" height={pxToRem(28)} sx={{ marginBottom: pxToRem(10) }}>
            <Typography
              variant="h4"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  fontSize: pxToRem(20),
                },
              })}
            >
              Welcome to Kaiform
            </Typography>
            <Typography
              variant="caption"
              sx={({ breakpoints }) => ({
                marginBottom: ".6rem",
                [breakpoints.down("md")]: {
                  fontSize: pxToRem(8),
                },
              })}
            >
              We&apos;re glad you&apos;re here. Join your team and start collaborating
            </Typography>
          </Box>

          <Box component="form" role="form">
            <Box
              className="flex-container-column"
              mb={3}
              gap={2}
              sx={{
                width: {
                  lg: pxToRem(422),
                  sm: "unset",
                },
              }}
            >
              <Box className="width-100">
                <Typography
                  variant="h6"
                  sx={({ breakpoints }) => ({
                    marginBottom: "0.2rem",
                    marginTop: pxToRem(20),
                    [breakpoints.down("md")]: {
                      fontSize: pxToRem(15),
                    },
                  })}
                >
                  Name
                </Typography>
                <Input
                  size="small"
                  value={inviteData ? `${inviteData.first_name} ${inviteData.last_name}` : ""}
                  disabled
                  // value={memberFields.name}
                  // onChange={(e) => handleInputChange("name", e.target.value)}
                  // error={showError && !memberFields.name}
                  fullWidth
                />
              </Box>
              <Box className="width-100">
                <Typography
                  variant="h6"
                  sx={({ breakpoints }) => ({
                    marginBottom: "0.2rem",
                    [breakpoints.down("md")]: {
                      fontSize: pxToRem(15),
                    },
                  })}
                >
                  Email Address
                </Typography>

                <Input
                  size="small"
                  disabled
                  value={inviteData ? inviteData.email : ""}
                  // value={memberFields.email}
                  // onChange={(e) => handleInputChange("email", e.target.value)}
                  // error={showError && !memberFields.email}
                  // helperText={
                  //   showError && !memberFields.email && `Please enter a valid email address.`
                  // }
                  fullWidth
                />
              </Box>
              <Box className="width-100">
                <Typography
                  variant="h6"
                  sx={({ breakpoints }) => ({
                    marginBottom: "0.2rem",
                    [breakpoints.down("md")]: {
                      fontSize: pxToRem(15),
                    },
                  })}
                >
                  Role
                </Typography>
                <Input
                  size="small"
                  disabled
                  value="admin"
                  // onChange={(e) => handleInputChange("role", e.target.value)}
                  // error={showError && !memberFields.role}
                  fullWidth
                />
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                id="next"
                fullWidth
                onClick={() => acceptTeamInvite(inviteData)}
              >
                <Typography variant="button" color="white">
                  Let&apos;s Go
                </Typography>
              </Button>
              <Loader
                className="margin-auto"
                toggle={verifyTeamInviteState.status === "pending"}
                sx={{ width: "98%", background: "transparent" }}
              />
            </Box>
            <Box className="flex-container justify-content-center">
              <Typography
                color="black"
                variant="d7"
                sx={({ breakpoints }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: pxToRem(8),
                  },
                  marginTop: 2,
                })}
              >
                By continuing, you agree to our Terms and Conditions
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </BasicLayout>
  );
}

export default Invites;

Invites.defaultProps = {
  inviteData: null,
};
Invites.propTypes = {
  status: PropTypes.string.isRequired,
  inviteData: PropTypes.any,
  acceptTeamInvite: PropTypes.func.isRequired,
  verifyTeamInviteState: PropTypes.instanceOf(Object).isRequired,
};
