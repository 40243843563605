import { createSlice } from "@reduxjs/toolkit";
import resources from "data/resources";
import { readFromLocalStorage, writeToLocalStorage } from "services/browserStorage/localStorage";

const initialState = {
  locales: [],
  activeOptionPath: null,
  selectedLocale: readFromLocalStorage("selectedLocale"),
  translations: resources.en.translation,
};

export const generalSlice = createSlice({
  name: "generals",
  initialState,
  reducers: {
    setSelectedLocale(state, action) {
      writeToLocalStorage("selectedLocale", action.payload);
      state.selectedLocale = action.payload;
    },
    setActiveOptionPath(state, action) {
      state.activeOptionPath = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearReference, setSelectedLocale, setSessionState, setActiveOptionPath } =
  generalSlice.actions;

export default generalSlice.reducer;
