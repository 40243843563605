import { API_URL } from "config/apiHandler";
import getIdentity from "config/getIdentity";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import uniqueId from "services/utils/generateUniqueId";
import Dispatcher from "store/Dispatcher";
import {
  useGetProvidersQuery,
  useRequestAuthMutation,
  useVerifyLoginMutation,
} from "store/services/authsApi";
import { setSelectedProvider, setSessionState } from "store/slices/authSlice";
import LoginView from "views/auths/Login";

// Set the dimensions and position of the external authentication popup and open it
const EXTERNAL_POPUP_WIDTH = 500;
const EXTERNAL_POPUP_HEIGHT = 600;

function Auth() {
  const navigate = useNavigate();
  const location = useLocation();

  const [externalPopup, setExternalPopup] = useState(null);
  const getProvidersState = useGetProvidersQuery("_", {
    skip: !!location.search, // Don't fetch providers if there's a search query (magic link)
  });
  const [requestOtpForAuth, requestAuthState] = useRequestAuthMutation();
  const [verifyOtpLogin, verifyAuthState] = useVerifyLoginMutation();

  useEffect(() => {
    if (!externalPopup) {
      return; // Do nothing if there's no externalPopup
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        if (timer) clearInterval(timer); // Stop the timer if the popup is closed
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const AUTH_VERIFICATION_URL = "/auths/verify";
      if (currentUrl.includes(AUTH_VERIFICATION_URL)) {
        externalPopup.close();

        navigate(AUTH_VERIFICATION_URL);

        setExternalPopup(null);
        if (timer) clearInterval(timer);
      }
    }, 20);
  }, [externalPopup]);

  const verifyAuth = async (requestBody) => {
    try {
      await verifyOtpLogin(requestBody).unwrap();
      navigate("/app/surveys");
      return true;
    } catch (error) {
      return false;
    }
  };

  const requestAuth = async (requestBody) => {
    try {
      await requestOtpForAuth(requestBody).unwrap();
      return true;
    } catch (error) {
      return false;
    }
  };

  const setSelectedAuthProvider = (provider) => {
    Dispatcher({ action: setSelectedProvider, payload: provider, isAsync: false });
  };

  const openSocialAuthPopup = async (provider) => {
    const sessionState = uniqueId(); // Generate a unique session state
    await Dispatcher({ action: setSelectedProvider, payload: provider, isAsync: false });
    await Dispatcher({ action: setSessionState, payload: sessionState, isAsync: false });
    const identifier = await getIdentity();
    const encodedValue = window.btoa(identifier);

    const left = window.screenX + (window.outerWidth - EXTERNAL_POPUP_WIDTH) / 2;
    const top = window.screenY + (window.outerHeight - EXTERNAL_POPUP_HEIGHT) / 2.5;
    const title = `WINDOW TITLE`;
    const url = `${API_URL}/auths/requests/social?state=${sessionState}&provider_id=${provider.id}&identifier=${encodedValue}`;
    const popup = window.open(
      url,
      title,
      `width=${EXTERNAL_POPUP_WIDTH},height=${EXTERNAL_POPUP_HEIGHT},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  };

  const handleClearErrorAndRetry = () => {
    const apiStates = [getProvidersState, requestAuthState];
    apiStates.forEach((state) => {
      if (state.isError) {
        if (state.refetch) {
          state.refetch();
        } else if (state.reset) {
          state.reset();
        }
      }
    });
  };

  return (
    <LoginView
      {...{
        getProvidersState,
        setSelectedAuthProvider,
        requestAuth,
        requestAuthState,
        verifyAuth,
        verifyAuthState,
        openSocialAuthPopup,
        handleClearErrorAndRetry,
      }}
    />
  );
}

export default Auth;
