import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import cookiesManipulator from "services/browserStorage/cookies";
import { useGetAuthSessionQuery, useVerifyLoginMutation } from "store/services/authsApi";
import VerifyView from "views/auths/Verify";

function Verify() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { selectedProvider, sessionState } = useSelector((state) => state.auths);
  const [verifyOtpLogin, verifyAuthState] = useVerifyLoginMutation();
  const getAuthSessionState = useGetAuthSessionQuery(
    {
      provider_id: selectedProvider.id,
      state: sessionState,
    },
    { skip: !!(location && location.search) } // Skip this action if the URL has a search query (for magic link).
    // Only run useGetAuthSessionQuery for social logins, which don't have a search query.
  );

  useEffect(() => {
    if (cookiesManipulator.getAuth().token) {
      navigate("/app/surveys");
    }
  }, [cookiesManipulator.getAuth().token]);

  const verifyAuth = async (requestBody) => {
    try {
      await verifyOtpLogin(requestBody).unwrap();
      navigate("/app/surveys");
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleClearErrorAndRetry = () => {
    const apiStates = [verifyAuthState];
    apiStates.forEach((state) => {
      if (state.isError) {
        if (state.refetch) {
          state.refetch();
        } else if (state.reset) {
          state.reset();
        }
      }
    });
    navigate("/auths/login");
  };

  useEffect(() => {
    if (location && location.search) {
      const signature = searchParams.get("signature");
      const decodedString = atob(signature);

      const urlSearchParams = new URLSearchParams(decodedString);

      const email = urlSearchParams.get("email");
      const token = urlSearchParams.get("token");
      const providerId = urlSearchParams.get("provider_id");
      const requestBody = {
        provider_id: providerId,
        email,
        token,
      };
      verifyAuth(requestBody);
    }
  }, []);

  useEffect(() => {
    if (getAuthSessionState.status === "fulfilled") {
      navigate("/app/surveys");
    }
  }, [getAuthSessionState.status]);

  return (
    <VerifyView
      verifyAuthState={verifyAuthState}
      getAuthSessionState={getAuthSessionState}
      onRetryClick={handleClearErrorAndRetry}
    />
  );
}

export default Verify;
